body {
  margin: 0;
  font-family: -apple-system, Rubik, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@bgCo: #f0f2f5;
@bk: #000;
@startW: 160px;
@timeHover: 0.5s;
@timeActive: 0.8s;
@mainW: 300px;
/*
@keyframes heartAnimate {
  0% {
    stroke-dasharray: 215, 215;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 330, 100;
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dasharray: 215, 215;
    stroke-dashoffset: -430;
  }
}
*/
@keyframes heartAnimate {
  0% {
    stroke-dasharray: 215, 215;
    stroke-dashoffset: -430;
  }
  50% {
    stroke-dasharray: 330, 100;
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dasharray: 215, 215;
    stroke-dashoffset: 0;
  }
}
@keyframes heartAnimate2 {
  from {
    stroke-dasharray: 430, 430;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dasharray: 430, 430;
    stroke-dashoffset: 430;
  }
}
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0;
  position: relative;
  padding: 100px 0;
}
.startBox {
  display: inline-block;
  width: @startW;
  position: relative;
  cursor: pointer;
  .fakeBtn {
    position: absolute;
    width: 97%;
    height: 92%;
    top: 4%;
    left: 1.5%;
    border: solid 1px transparent;
    border-radius: @startW * 0.08;
    transition: all @timeHover ease-in-out;
  }
}
.buttonBorder {
  stroke: #67abee;
  stroke-dasharray: 1544, 1544;
  stroke-dashoffset: 0;
  transition: all @timeActive ease-in-out;
}
.heartOuter {
  transform-origin: 50% 50%;
  transition: all @timeHover * 0.8 ease-in-out;
  transition-delay: @timeHover * 0.7;
  .heart {
    stroke-dasharray: 430, 430;
    stroke-dashoffset: 430;
    stroke: #3e60f3;
    animation: heartAnimate 1s infinite;
    animation-timing-function: linear;
  }
}
.start {
  stroke: #3e60f3;
  stroke-dasharray: 220, 220;
  stroke-dashoffset: 220;
  transition: all @timeHover * 1.3 ease-in-out;
  transition-delay: 0s;
}
.btnInner {
  display: inline-block;
  position: relative;
  top: 3px;
  z-index: 1;
  width: @mainW;
  height: @mainW;
  opacity: 0;
  .moon {
    stroke: @bk;
    fill: @bgCo;
    stroke-width: 3;
    stroke-dasharray: 2355, 2355;
    stroke-dashoffset: 2355;
  }
  .star {
    stroke: @bk;
    fill: @bgCo;
    stroke-width: 1;
    transform-origin: center;
  }
  .lf1 {
    fill: @bgCo;
    stroke: @bk;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .lf2 {
    fill: @bgCo;
    stroke: @bk;
    stroke-width: 1.3;
    stroke-miterlimit: 0;
  }
  .flw1_a {
    fill: @bgCo;
    stroke: @bk;
    stroke-width: 3;
  }
  .flw1_b {
    fill: @bk;
    stroke: @bk;
  }
  .pl2 {
    fill: none;
    stroke: @bk;
    stroke-width: 2.2677;
    stroke-miterlimit: 10;
  }
  .pl3 {
    fill: none;
    stroke: @bk;
    stroke-miterlimit: 10;
  }
  .pl4 {
    fill: #ffffff;
    stroke: @bk;
    stroke-miterlimit: 10;
  }
  .lvt {
    fill: none;
    stroke: @bk;
    stroke-width: 1.7;
  }
  .abBox {
    position: absolute;
    &.plants {
      width: 100%;
      top: 0;
      left: 0;
    }
    &.leaf {
      width: 60%;
      top: 58%;
      left: -8%;
      transform: scale(0);
    }
    &.flower1 {
      width: 55%;
      top: 60%;
      left: 30%;
      transform: scale(0);
    }
    &.flower2 {
      width: 60%;
      top: 43%;
      left: -7%;
      transform: scale(0);
    }
    &.flower3 {
      width: 60%;
      top: 28%;
      left: 2%;
      transform: scale(0);
    }
    &.flower4 {
      width: 60%;
      top: 52%;
      left: 12%;
      transform: scale(0);
    }
    &.star1 {
      width: 2.6%;
      left: 27%;
      top: 37%;
      transform: scale(0);
    }
    &.star2 {
      width: 2%;
      left: 41%;
      top: 22%;
      transform: scale(0);
    }
    &.star3 {
      width: 2.5%;
      left: 50%;
      top: 17%;
      transform: scale(0);
    }
    &.star4 {
      width: 3.5%;
      left: 34%;
      top: 21%;
      transform: scale(0);
    }
    &.txts {
      top: 32%;
      left: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        display: flex;
        align-items: center;
        .txt {
          font-size: 22px;
          line-height: 1;
          letter-spacing: 20px;
          opacity: 0;
        }
      }
      .time {
        display: flex;
        align-items: center;
        color: #999;
        .txt {
          font-size: 12px;
          line-height: 1;
          letter-spacing: 1px;
          transform: rotateX(90deg);
          position: relative;
          display: inline-block;
        }
        .t1 {
          display: inline-block;
          position: relative;
          backface-visibility: hidden;
          transform: rotateX(0deg);
        }
        .t2 {
          display: inline-block;
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotateX(180deg);
        }
      }
    }
  }
}
.button {
  .startBox {
    &:hover {
      .heartOuter {
        transition-delay: 0s;
        transform: scale(0.8) translate(-35%, 0px);
        .heart {
        }
      }
      .start {
        stroke-dashoffset: 0;
        transition-delay: @timeHover * 0.5;
      }
      .fakeBtn {
        box-shadow: 0 0 10px rgba(106, 140, 198, 0.8);
      }
    }
  }
  &.active {
    .startBox {
      .heartOuter {
        .heart {
          animation: heartAnimate2 @timeActive 1;
        }
      }
      .start {
        stroke-dashoffset: 220;
        transition: all @timeActive ease-in-out;
      }
      .fakeBtn {
        box-shadow: 0 0 0 transparent;
      }
    }
    .buttonBorder {
      stroke-dashoffset: 1544;
      transition-delay: @timeActive;
    }
    .btnInner {
      opacity: 1;
      transition: opacity @timeHover ease-in-out;
      .moon {
        stroke-dashoffset: 4710;
        transition: all @timeActive * 1.5 ease-in-out;
        transition-delay: @timeActive * 1.9;
      }
      .abBox {
        transition: all @timeActive * 2 ease-in-out;
        &.leaf {
          transform: scale(1);
          transition-delay: @timeActive * 2 + 2.1;
        }
        &.flower1 {
          transform: scale(1);
          transition-delay: @timeActive * 2 + 1.3;
        }
        &.flower2 {
          transform: scale(1);
          transition-delay: @timeActive * 2 + 1.6;
        }
        &.flower3 {
          transform: scale(1);
          transition-delay: @timeActive * 2 + 1.9;
        }
        &.flower4 {
          transform: scale(1);
          transition-delay: @timeActive * 2 + 1;
        }
        &.star1 {
          transition: all 0.6s ease-in-out;
          transition-delay: @timeActive * 6;
          transform: scale(1);
        }
        &.star2 {
          transition: all 0.6s ease-in-out;
          transition-delay: @timeActive * 6 + 0.2s;
          transform: scale(1);
        }
        &.star3 {
          transition: all 0.6s ease-in-out;
          transition-delay: @timeActive * 6 + 0.4s;
          transform: scale(1);
        }
        &.star4 {
          transition: all 0.6s ease-in-out;
          transition-delay: @timeActive * 6 + 0.6s;
          transform: scale(1);
        }
        &.txts {
          .name {
            .txt {
              letter-spacing: 5px;
              transition: all 3s ease-in-out;
              transition-delay: 5s;
              opacity: 1;
            }
          }
          .time {
            perspective: 100px;
            transform-style: preserve-3d;
            each(range(9), {
              .txt:nth-child(@{value}) {
                transition: all 1.8s ease-in-out;
                transition-delay: @value * 0.22s + 6s;
                transform: rotateX(0deg);
              }
            });
            .t1 {
              transition: all 1s ease-in-out;
              transition-delay: 9.6s;
              transform: rotateX(180deg);
            }
            .t2 {
              transition: all 1s ease-in-out;
              transition-delay: 9.6s;
              transform: rotateX(0deg);
            }
          }
        }
      }
    }
  }
  &.colored {
    .btnInner {
      .moon {
        stroke: #ffe770;
        fill: #fffdc7;
        transition-property: stroke, fill;
        transition-duration: 0.6s;
        transition-delay: 0.2s;
      }
      .star {
        stroke: #ffac00;
        fill: #ffac00;
        transition-property: stroke, fill;
        transition-duration: 0.6s;
        transition-delay: 0.6s;
      }
      .lf1 {
        fill: #e3ffd3;
        stroke: #88b680;
        transition-property: stroke, fill;
        transition-duration: 0.6s;
        transition-delay: 0.85s;
      }
      .lf2 {
        fill: none;
        stroke: #88b680;
        transition-property: stroke, fill;
        transition-duration: 0.6s;
        transition-delay: 0.9s;
      }
      .flw1_a {
        fill: #fee5be;
        stroke: #ffc679;
        transition-property: stroke, fill;
        transition-duration: 0.6s;
        transition-delay: 0.4s;
      }
      .flw1_b {
        fill: #ffc679;
        stroke: #ffc679;
        transition-property: stroke, fill;
        transition-duration: 0.6s;
        transition-delay: 0.5s;
      }
      .pl2 {
        stroke: #e93434;
        transition-property: stroke, fill !important;
        transition-duration: 0.6s !important;
        transition-delay: 1.4s !important;
      }
      .pl3 {
        stroke: #e93434;
        transition-property: stroke, fill !important;
        transition-duration: 0.6s !important;
        transition-delay: 1.6s !important;
      }
      .pl4 {
        fill: #ffd7db;
        stroke: #e93434;
        transition-property: stroke, fill !important;
        transition-duration: 0.6s !important;
        transition-delay: 1.7s !important;
      }
      .lvt {
        stroke: #953723;
        transition-property: stroke, fill !important;
        transition-duration: 0.9s !important;
        transition-delay: 0s !important;
      }
      .abBox {
        &.txts {
          .name {
            .txt {
              color: #883523;
              transition-property: color;
              transition-duration: 0.2s;
              transition-delay: 0s;
            }
          }
        }
      }
    }
  }
}
